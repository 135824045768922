<template>
  <div>
    <div v-if="!loaded" class="d-flex justify-content-center mb-3">
      <ct-centered-spinner />
    </div>
    <div v-else>
      <b-card>
        <b-card-text>
          <div v-if="lawOnCallTrialEligible">
            <h3 class="mt-0">
              {{ `${product.name} - Free Trial (${ product.trial_duration_days } Days)` }}
              &mdash; {{ product.price | dollars }}
            </h3>
            <p>After {{ product.trial_duration_days }} days, the price will be adjusted to $9 per month.</p>
          </div>
          <h3 v-else class="mt-0">
            {{ product.name }} &mdash; {{ product.price | dollars }}
          </h3>
        </b-card-text>

        <multi-select
          :value="selectedJurisdictions"
          track-by="id"
          :multiple="true"
          :disabled="addingToCart"
          label="state_province_region"
          placeholder="Select from the available jurisdictions for law on call service"
          :options="availableJurisdictions"
          @select="addServiceToCart($event.state_province_region)"
          @remove="removeFromCart({ id: cartItemForJurisdiction($event.state_province_region).id })"
        >
          <template slot="singleLabel" slot-scope="{ option }">
            {{ option.jurisdiction }}
          </template>
        </multi-select>
      </b-card>
    </div>
  </div>
</template>

<script>
import { dollars } from '@/filters'
import MultiSelect from 'vue-multiselect'
import { mapActions, mapGetters } from 'vuex'
import CtCenteredSpinner from '@/components/shared/CtCenteredSpinner'

export default {
  name: 'LawOnCallProductSpecialization',

  components: { MultiSelect, CtCenteredSpinner },

  filters: {
    dollars,
  },

  props: {
    product: Object,
    company: Object,
  },

  data() {
    return {
      addingToCart: false,
      loaded: false,
    }
  },

  computed: {
    ...mapGetters('checkout', ['cartItems']),
    ...mapGetters('jurisdictions', ['jurisdictions', 'findByAbbreviation', 'findByName']),
    ...mapGetters('lawOnCall', ['lawOnCallTrialEligible']),
    availableJurisdictions() {
      // It took us 4 years to get a new jurisdiction so lazy harcoding . Granted, perhaps look at this being a backend call
      return this.jurisdictions.filter(j => ['Utah', 'Arizona'].includes(j.state_province_region))
    },
    selectedJurisdictions() {
      return this
        .cartItems
        .filter(item => item.display_info.product_category === 'law-on-call')
        .map(item => this.findByAbbreviation(item.data.state) || this.findByName(item.data.state))
    },
  },

  async mounted() {
    this.loaded = false
    await this.determineLawOnCallTrialEligibility(this.company.id)
    this.loaded = true
  },

  methods: {
    ...mapActions('checkout', ['addToCart', 'removeFromCart']),
    ...mapActions('lawOnCall', ['determineLawOnCallTrialEligibility']),
    async addServiceToCart(jurisdiction) {
      this.addingToCart = true

      await this.addToCart({
        ...this.product,
        data: { state: jurisdiction },
        skipResolveOptionalItems: true,
      })

      this.addingToCart = false
      this.$emit('completed')
    },
    cartItemForJurisdiction(jurisdiction) {
      return this.cartItems.find(
        item => item.product_id === this.product.id && item.data.state === jurisdiction
      )
    },
  },

}
</script>

<style lang="scss" scoped>

</style>
